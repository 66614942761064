import styled from '@emotion/styled'
import { BgHole } from 'assets/images';
import { sizes } from 'service';

export const Container = styled.div`
  display: block;
`;

export const HoleContainer = styled.div`
  &.hole {
    display: ${prop => prop.hide ? 'none' : 'block'} !important;
  }
  width: ${sizes.holeWidth}px;
  height: ${sizes.holeWidth}px;
  background: url(${BgHole});
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  canvas {
    width: 33px;
    height: 33px;
  }
`;